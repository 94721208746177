import React from 'react';
import { Motorization } from '../components'
import { Page } from '../components/shared';
import './main.css';

const App = () => {
  return (
    <main>
      <Page>
        <Motorization />
      </Page>
    </main>
  )
}

export default App
